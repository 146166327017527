import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className='footer-socials'>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.linkedin.com/in/mark-gerald-duenas/'
        >
          <i className='fab fa-linkedin-in'></i>
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://github.com/MarkDuenas?tab=repositories'
        >
          <i className='fab fa-github'></i>
        </a>
        <a href='mailto: markgdlduenas@gmail.com'>
          <i className='far fa-envelope'></i>
        </a>
      </div>
      <h5>
        MARK DUENAS <span>&copy; 2021</span>
      </h5>
    </footer>
  );
};

export default Footer;
