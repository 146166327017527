import React from "react";
import profile from "../../images/profile.svg";
import waves from "../../images/waves.svg";
import { Link } from "react-scroll";
import "./Hero.scss";
const Hero = () => {
  return (
    <>
      <div className='hero'>
        <div className='left-content'>
          <h4>SOFTWARE DEVELOPER</h4>
          <h1>Hi I'm Mark! Please have a look around.</h1>

          <button>
            <Link to='projects' spy={true} smooth={true}>
              <span>&#128071;</span> Explore
            </Link>
          </button>
        </div>
        <div className='right-content'>
          <img src={profile} alt='markduenas' />
        </div>
      </div>
      <img className='wave' src={waves} alt='waves' />
    </>
  );
};

export default Hero;
