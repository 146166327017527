import React from "react";
import "./Project1.scss";

const Project2 = ({ handleClose }) => {
  return (
    <div className='container'>
      <i className='far fa-times-circle' onClick={handleClose}></i>
      <h3>SPACE PALACE</h3>
      <p>
        Enjoy our demo video of the project in action! The demo starts at
        <a href='https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fcodefellows.org%2Fvideos%2F301549021583836%2F&show_text=false&width=560&t=1842'>
          30:44
        </a>
        and ends at
        <a href='https://www.facebook.com/plugins/video.php?height=314/&href=https%3A%2F%2Fwww.facebook.com%2Fcodefellows.org%2Fvideos%2F301549021583836%2F&show_text=false&width=560&t=2035'>
          33:55
        </a>
      </p>
      <iframe
        className='video'
        title='atomic demo'
        src='https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fcodefellows.org%2Fvideos%2F301549021583836%2F&show_text=false&width=560&t=1842'
        width='80%'
        height='1000'
        style={{ border: "none", overflow: "hidden" }}
        scrolling='no'
        frameBorder='0'
        allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
        allowFullScreen={true}
      ></iframe>
      <div className='bullet-points'>
        <ul>
          <li>
            Creates a voice command VS Code extension that eliminates repetive
            typing and generates designated code snippets based on voiced key
            words.
          </li>
          <li>
            Integrated Google Cloud Speech-to-Text API in order to turn voice
            recording into text, that will then trigger commands and insert
            snippets.
          </li>
          <li>
            Currently only works with basic snippets, but it's made so snippets
            can easily be added.
          </li>
          <li>
            Possibility of making into an open-source project to expand the
            extensions reach even further.
          </li>
        </ul>
      </div>
      <div className='links'>
        <p>
          <a
            rel='noreferrer'
            target='_blank'
            href='https://marketplace.visualstudio.com/items?itemName=ATTMN.atomic'
          >
            Published Extension
          </a>
        </p>
        <p>
          <a
            rel='noreferrer'
            target='_blank'
            href='https://github.com/MarkDuenas/Project-ATTMN/blob/dev/README.md'
          >
            GitHub Repo
          </a>
        </p>
      </div>
    </div>
  );
};

export default Project2;
