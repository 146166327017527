import React from "react";
import AboutMe from "./components/AboutMe/AboutMe.js";
import ContactMe from "./components/ContactMe/ContactMe.js";
import Footer from "./components/Footer/Footer.js";
import Header from "./components/Header/Header.js";
import Hero from "./components/Hero/Hero.js";
import Projects from "./components/Projects/Projects.js";

const App = () => {
  return (
    <>
      <Header />
      <Hero />
      <Projects />
      <AboutMe />
      <ContactMe />
      <Footer />
    </>
  );
};

export default App;
