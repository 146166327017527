import React from "react";
import "./Header.scss";
import { Link } from "react-scroll";

const Header = () => {
  return (
    <header>
      <h3 className='logo'>Mark Duenas</h3>
      <div>
        <ul>
          <li>
            <Link to='projects' spy={true} smooth={true}>
              Projects
            </Link>
          </li>
          <li>
            <Link to='about' spy={true} smooth={true}>
              About Me
            </Link>
          </li>
          <li>
            <Link to='contact' spy={true} smooth={true}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className='socials'>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://www.linkedin.com/in/mark-gerald-duenas/'
        >
          <i className='fab fa-linkedin-in'></i>
        </a>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://github.com/MarkDuenas?tab=repositories'
        >
          <i className='fab fa-github'></i>
        </a>
        <a href='mailto: markgdlduenas@gmail.com'>
          <i className='far fa-envelope'></i>
        </a>
      </div>
    </header>
  );
};

export default Header;
