import React from "react";
import "./AboutMe.scss";
import aboutImg from "../../images/aboutme.png";
const AboutMe = () => {
  return (
    <>
      <div className='about'>
        <h1>ABOUT ME</h1>
        <div className='about-content'>
          <div className='about-image'>
            <img src={aboutImg} alt='mark duenas' />
          </div>
          <div className='about-paragraph'>
            <h6>Hello There!</h6>
            <p>
              My name is Mark Duenas and I am a U.S. Navy Vet turned Software
              Engineer. My passion for programming has started when I was very
              young when I wanted to develop my own games. After graduating
              highschool I decided to join the military where I became an
              Electronics Technician working on missile launching equipment.
            </p>
            <p>
              Fast-forward to today, I seperated from the military and decided
              to follow my passion in programming. I joined several coding
              schools to learn. Now I am Full-Stack developer, looking to prove
              my worth as a software developer by combining previous experiences
              with new programming knowledge to develop applications that focus
              on efficiency, scalability and creativity.
            </p>
            <p>
              When I’m not programming one of my top priorities are health and
              fitness. I love outdoor activites. I am always up for hikes, and I
              am an avid snowboarder in the winter time.
            </p>
            <div className='skills'>
              <p>
                <span>LANGUAGES:</span> JavaScript, Python, C#, SQL, HTML, CSS
              </p>
              <p>
                <span>SKILLS and TOOLS:</span> React.js, Node.js, jQuery,
                Express.js, MongoDB, Mongoose, MySQL, PostgresSQL, Django,
                Flask, Heroku, Netlify, AWS(Elastic Beanstalk), Material-UI,
                Bootstrap, SASS, Git/Github, Jest.js, React Testing Library,
                CI/CD, Test Driven Development
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutMe;
