import React, { useState } from "react";
import "./Projects.scss";
import Project1 from "../ProjectModal/Project1";
import Project2 from "../ProjectModal/Project2";
import spacePalace from "../../images/space-palace.png";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Backdrop, Fade } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Projects = () => {
  const classes = useStyles();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <section className='projects'>
      <h1>PROJECTS</h1>
      <div className='p1'>
        <div className='left'>
          <h3 onClick={handleOpen1}> SPACE PALACE </h3>
          <h5>E-Commerce with a twist</h5>
          <p>
            A new theme for the common e-comerce sites. With a futuristic twist,
            buy all the neccesary products for your space travel needs.
          </p>
        </div>
        <img src={spacePalace} alt='space palace' onClick={handleOpen1} />
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={open1}
          onClose={handleClose1}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open1}>
            <Project1 handleClose={handleClose1} />
          </Fade>
        </Modal>
      </div>
      <div className='p2'>
        <div className='left'>
          <h3 onClick={handleOpen2}> ATOMIC </h3>
          <h5>A new VS Code experience</h5>
          <p>
            A VS Code extension that eliminates repetitive typing and generates
            designated code snippets based on voiced key words.
          </p>
        </div>
        <iframe
          title='atomic demo'
          src='https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fcodefellows.org%2Fvideos%2F301549021583836%2F&show_text=false&width=560&t=1842'
          width='100%'
          height='400'
          style={{ border: "none", overflow: "hidden" }}
          scrolling='no'
          frameBorder='0'
          allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
          allowFullScreen={true}
        ></iframe>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={open2}
          onClose={handleClose2}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open2}>
            <Project2 handleClose={handleClose2} />
          </Fade>
        </Modal>
      </div>
    </section>
  );
};

export default Projects;
