import React from "react";
import "./Project1.scss";
import space from "../../images/space-palace.png";

const Project1 = (props) => {
  const { handleClose } = props;
  return (
    <div className='container'>
      <i className='far fa-times-circle' onClick={handleClose}></i>
      <h3>SPACE PALACE</h3>
      <img src={space} alt='space' />
      <div className='bullet-points'>
        <ul>
          <li>
            Fully functional E-Commerce website with cart and checkout
            functionality
          </li>
          <li>
            Integrated seperate deployed back-end that handles user login and
            registration validation along with retrieving products to be
            displayed on front end.
          </li>
          <li>Utilized Redux to handle complex state management.</li>
          <li>
            Technologies used: React, Node, Express, MongoDB Atlas, Mongoose,
            Material-UI, JSONWebToken, Redux.
          </li>
        </ul>
      </div>
      <div className='links'>
        <p>
          <a
            rel='noreferrer'
            target='_blank'
            href='http://spacepalacefe.herokuapp.com/'
          >
            Deployed Site
          </a>
        </p>
        <p>
          <a
            rel='noreferrer'
            target='_blank'
            href='https://github.com/Arthur-Lozano/spacepalacefe'
          >
            GitHub Repo Front-End
          </a>
        </p>
        <p>
          <a
            rel='noreferrer'
            target='_blank'
            href='https://github.com/Arthur-Lozano/spacepalacedb'
          >
            GitHub Repo Back-End
          </a>
        </p>
      </div>
    </div>
  );
};

export default Project1;
