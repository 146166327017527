import React, { useState } from "react";
import "./ContactMe.scss";
import emailjs from "emailjs-com";

const ContactMe = () => {
  const [email, setEmail] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setEmail({ ...email, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAIL_USER_ID
      );
    } catch (e) {
      console.warn(e.message);
    }

    alert("Message sent! I'll get back to you shortly!");

    setEmail({
      name: "",
      email: "",
      message: "",
    });
  };
  return (
    <div className='contact'>
      <h1>CONTACT</h1>
      <div className='contact-form'>
        <form onSubmit={handleSubmit}>
          <input
            value={email.name}
            type='text'
            placeholder='NAME'
            name='name'
            onChange={handleChange}
          />
          <input
            value={email.email}
            type='email'
            placeholder='EMAIL'
            name='email'
            onChange={handleChange}
          />
          <textarea
            value={email.message}
            rows='10'
            placeholder='YOUR MESSAGE'
            name='message'
            onChange={handleChange}
          ></textarea>
          <button>SUBMIT</button>
        </form>
      </div>
    </div>
  );
};

export default ContactMe;
